import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Row, Button } from 'reactstrap';

export class Panel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: this.props.startCollapsed,
    };
  }
  toggle = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  render() {
    return (
      <div className='mt-5 pb-1'>
        <Button
          color='link'
          block
          className='text-dark cursor-pointer border-bottom mb-2 pb-0 text-left'
          onClick={this.toggle}
        >
          <h4 className='d-inline-block'>
            <FontAwesomeIcon
              icon={[
                'fal',
                this.state.collapsed ? 'chevron-down' : 'chevron-up',
              ]}
              className='mr-3'
            />
            {this.props.label}
          </h4>
          {this.props.badge !== undefined && (
            <span className='badge badge-pill badge-secondary ml-2 d-inline-block align-text-bottom text-size-default'>
              {this.props.badge}
            </span>
          )}
        </Button>
        <Collapse isOpen={!this.state.collapsed}>
          {this.props.row && <Row>{this.props.children}</Row>}
          {!this.props.row && this.props.children}
        </Collapse>
      </div>
    );
  }
}
