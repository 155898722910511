import Axios from 'axios';
import moment from 'moment';
import { isObject } from 'lodash-es';
import { store } from './App/configureStore';

export const apiRoot = '/api/';

export function httpFactory() {
  const { oidc } = store.getState();

  return Axios.create({
    baseURL: apiRoot,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${oidc.user.access_token}`,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache', //HTTP 1.0 fallback
    },
  });
}

const convertToQueryParam = (key, value) => {
  if (value instanceof moment) {
    value = value.format('MM/DD/YYYY');
  }

  if (isObject(value)) {
    if (Array.isArray(value)) {
      return value
        .map((innerValue) => convertToQueryParam(key, innerValue))
        .join('&');
    }

    if ('value' in value) value = value.value;
  }

  return `${key}=${encodeURIComponent(value)}`;
};

export const convertParamsToQueryString = (params) =>
  Object.keys(params)
    .filter((key) => params[key] !== null)
    .map((key) => convertToQueryParam(key, params[key]))
    .join('&');

export class Api {
  constructor(controllerName) {
    this.controllerName = controllerName;
  }
  add = (data) => httpFactory().post(this.controllerName, data);
  update = (data) => httpFactory().put(this.controllerName, data);
  delete = (id) => httpFactory().delete(`${this.controllerName}/${id}`);
  load = (id) => {
    let url = this.controllerName;

    if (id) url += `/${id}`;

    return httpFactory().get(url);
  };
  post = (actionName, data) =>
    httpFactory().post(`${this.controllerName}/${actionName}`, data);
  get = (actionName, params) => {
    let url = this.controllerName;

    if (actionName) url = `${url}/${actionName}`;

    if (params) {
      url += `?${convertParamsToQueryString(params)}`;
    }

    return httpFactory().get(url);
  };
  put = (actionName, data) =>
    httpFactory().put(`${this.controllerName}/${actionName}`, data);
}
