import { isSuccessAction } from '@/Common/ReducerHelpers';
import { actions } from './ClaimSearchActions';
import { actions as clientActions } from '../../Clients/ClientActions';
import { sort } from '@/Common/Utils';
import moment from 'moment';

const initState = {
  filter: {
    damageFromDate: moment().subtract(1, 'months'),
  },
  pageIndex: 0,
  pageSize: 20,
  pageCount: 1,
  sort: {
    field: 'damageDate',
    direction: 'desc',
  },
  data: [],
};

export default function claimSearch(state = initState, action) {
  if (action.type === clientActions.actionNames.select) {
    return { ...initState };
  }

  if (isSuccessAction(action) && action.type === actions.actionNames.load) {
    const pageCount = Math.ceil(action.payload.length / state.pageSize);
    return {
      ...state,
      data: sort(action.payload, state.sort.field, state.sort.direction),
      pageIndex: 0,
      pageCount,
    };
  }

  switch (action.type) {
    case actions.actionNames.filterChange:
      return {
        ...state,
        filter: { ...action.newFilter },
      };
    case actions.actionNames.pageChange:
      return {
        ...state,
        pageIndex: action.newIndex,
      };
    case actions.actionNames.sortChange:
      const newSort = { field: action.field, direction: action.direction };
      return {
        ...state,
        data: sort(state.data, newSort.field, newSort.direction),
        sort: newSort,
        pageIndex: 0,
      };
    default:
  }

  return state;
}
