import { httpFactory } from '../api';
import { lookup } from 'mime-types';

export const getExtension = (fileName) =>
  fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();

export const getName = (fullName) =>
  fullName.substring(fullName.lastIndexOf('/') + 1);

export const getLocation = (fullName) =>
  fullName.substring(0, fullName.lastIndexOf('/'));

export const getIconForFile = (fileName) => getIcon(getExtension(fileName));

export const isImageFile = (fileName) =>
  ['png', 'jpg', 'jpeg', 'tif', 'gif'].indexOf(getExtension(fileName)) > -1;

export function getIcon(extension) {
  switch (extension) {
    case 'avi':
    case 'mov':
      return 'file-video';
    case 'wav':
    case 'wma':
      return 'file-audio';
    case 'doc':
    case 'docx':
      return 'file-word';
    case 'xls':
    case 'xlsx':
      return 'file-excel';
    case 'txt':
    case 'csv':
      return 'file-alt';
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'tif':
    case 'gif':
      return 'file-image';
    case 'html':
      return 'file-code';
    case 'pdf':
      return 'file-pdf';
    default:
      return 'file';
  }
}

const downloadBlob = async (url, fileName) => {
  fileName = fileName || getName(url);
  const mimeType = lookup(fileName);

  const response = await httpFactory().get(url, { responseType: 'blob' });
  return new Blob([response.data], { type: mimeType });
};

const delayedObjetUrlRelease = (objectUrl) =>
  setTimeout(function() {
    window.URL.revokeObjectURL(objectUrl);
  }, 1000);

export async function fileOpen(url, fileName) {
  const blob = await downloadBlob(url, fileName);

  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, fileName || getName(url));
    return;
  }

  const objectUrl = window.URL.createObjectURL(blob);

  window.open(objectUrl);

  delayedObjetUrlRelease(objectUrl);
}
