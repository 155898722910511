import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input, Button, Tooltip } from 'reactstrap';
import { httpFactory } from '@/api';
import { withRouter } from 'react-router';
import './LookupBox.scss';

class LookupBox extends Component {
  state = {
    claimNumber: '',
    notFound: false,
    loading: false,
  };
  onChange = (e) => {
    const value = e.currentTarget ? e.currentTarget.value : e.target.value;

    this.setState({ claimNumber: value, notFound: false });
  };
  onSubmit = async (e) => {
    const { claimNumber } = this.state;

    e.preventDefault();

    if (claimNumber.trim() === '') return;

    this.setState({ loading: true });

    try {
      var response = await httpFactory().get(
        `Claims/ClaimNumberLookup/${claimNumber}`,
      );

      this.props.history.push(
        `/Claim/${response.data.claimIdSource}/${response.data.claimId}`,
      );

      this.setState({ claimNumber: '', loading: false });
    } catch (ex) {
      this.setState({ notFound: true, loading: false });
    }
  };
  render() {
    return (
      <Form
        inline
        className='claim-number-lookup-form'
        onSubmit={this.onSubmit}
      >
        <Input
          placeholder='Claim #'
          value={this.state.claimNumber}
          onChange={this.onChange}
          id='ClaimNumberLookupInput'
        />
        <Button type='submit' disabled={this.state.loading}>
          {!this.state.loading && <FontAwesomeIcon icon={['fal', 'search']} />}
          {this.state.loading && (
            <FontAwesomeIcon icon={['fal', 'spinner-third']} spin={true} />
          )}
        </Button>
        <Tooltip
          placement='bottom'
          isOpen={this.state.notFound}
          target='ClaimNumberLookupInput'
        >
          <FontAwesomeIcon
            icon={['fal', 'exclamation-circle']}
            className='text-danger mr-1'
            size='lg'
          />{' '}
          Claim number not found
        </Tooltip>
      </Form>
    );
  }
}

export default withRouter(LookupBox);
