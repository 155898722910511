import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserCircle,
  faSearch,
  faSpinnerThird,
  faChevronUp,
  faChevronDown,
  faFilePdf,
  faFileVideo,
  faFileAudio,
  faFileWord,
  faFileExcel,
  faFileAlt,
  faFileImage,
  faFileCode,
  faFile,
  faFolder,
  faExclamationCircle,
  faCheck,
  faCheckSquare,
  faSquare,
  faChevronLeft,
  faChevronRight,
  faBars,
  faDownload,
  faRedo,
} from '@fortawesome/pro-light-svg-icons';
import {
  faBackward,
  faForward,
  faStepBackward,
  faStepForward,
  faMobileAlt,
  faBuilding,
  faQuestionCircle,
} from '@fortawesome/pro-solid-svg-icons';

export default function() {
  library.add(
    faUserCircle,
    faSearch,
    faSpinnerThird,
    faChevronUp,
    faChevronDown,
    faFilePdf,
    faBackward,
    faForward,
    faStepBackward,
    faStepForward,
    faFileVideo,
    faFileAudio,
    faFileWord,
    faFileExcel,
    faFileAlt,
    faFileImage,
    faFileCode,
    faFile,
    faFolder,
    faExclamationCircle,
    faMobileAlt,
    faBuilding,
    faQuestionCircle,
    faCheck,
    faCheckSquare,
    faSquare,
    faChevronLeft,
    faChevronRight,
    faBars,
    faDownload,
    faRedo,
  );
}
