import { ApiActions, apiAction } from '@/Common/ActionHelpers';

class ClaimViewActions extends ApiActions {
  load = (source, id) => async (dispatch, getState) => {
    const key = `${source}/${id}`;
    return await apiAction(this.actionNames.load, () => this.api.get(key));
  };
}

export const actions = new ClaimViewActions('claimView', 'Claims');
