import { bindActionCreators } from 'redux';
import { store } from '../App/configureStore';
import { Api } from '../api';

export class ApiActions {
  constructor(namespace, apiControllerName, customActionNames) {
    this.namespace = namespace;

    this.actionNames = actionNameFactory(
      namespace,
      ['load', 'add', 'update', 'delete', 'search'].concat(customActionNames),
    );
    this.api = new Api(apiControllerName);
  }
  load = (id) => () =>
    apiAction(this.actionNames.load, () => this.api.load(id));
  add = (object) => () =>
    apiAction(this.actionNames.add, () => this.api.add(object), object);
  update = (object) => () =>
    apiAction(this.actionNames.update, () => this.api.update(object), object);
  delete = (id) => () =>
    apiAction(this.actionNames.delete, () => this.api.delete(id), { id });
}

export const actionNameFactory = (namespace, actionNames) =>
  actionNames.reduce((obj, actionName) => {
    obj[actionName] = `${namespace}.${actionName}`;
    return obj;
  }, {});

export const apiAction = async (actionName, apiFunc, params) => {
  try {
    store.dispatch({ type: actionName, status: 'processing' });
    var response = await apiFunc();
    const payload = response ? response.data : null;
    store.dispatch({
      type: actionName,
      status: 'success',
      payload,
      params,
    });
    return payload;
  } catch (error) {
    store.dispatch({
      type: actionName,
      status: 'error',
      error,
      params,
    });
    throw error;
  }
};

export const mapDispatchToPropsFactory = (actions) => (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});
