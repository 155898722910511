import { isSuccessAction, isLoadingAction } from '@/Common/ReducerHelpers';
import { actions } from './ClaimViewActions';

const initState = {
  locateService: {},
  damager: {},
  photos: [],
  documents: [],
  notes: [],
  narratives: [],
  damageAddress: {},
  assets: [],
};

export default function claimSearch(state = initState, action) {
  if (action.type === actions.actionNames.load) {
    if (isLoadingAction(action)) return initState;

    if (isSuccessAction(action)) return action.payload;
  }

  return state;
}
