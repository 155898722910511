import { apiAction } from '@/Common/ActionHelpers';
import { Api } from '../api';

export const actions = {
  actionNames: {
    load: 'clients.load',
    select: 'clients.select',
  },
  select: (client) => ({
    type: actions.actionNames.select,
    client,
  }),
  load: () => async () =>
    await apiAction(actions.actionNames.load, () => {
      const api = new Api('Clients');
      return api.get();
    }),
};
