import { createUserManager } from 'redux-oidc';

const domain = 'cmrclaims.com';
const host = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`;

const segments = window.location.hostname.replace(`.${domain}`, '').split('.');
let env = segments.length > 1 ? `.${segments[1]}` : '';

//test subdomain uses production identity
if (env === '.test') env = '';

//old dev subdomain should use "dev"
if (env === '.beta') env = '.dev';

const authority =
  window.location.hostname === 'localhost'
    ? 'https://identity.dev.cmrclaims.com'
    : `https://Identity${env}.${domain}`;

const oidcSettings = {
  client_id: 'Customer.Portal',
  redirect_uri: `${host}/callback`,
  response_type: 'token id_token',
  scope:
    'openid profile email cmr.profile cmr.customerportal Cmr.CustomerPortal.Api',
  authority: authority,
  silent_redirect_uri: `${host}/silent-renew`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: false,
};

var manager = createUserManager(oidcSettings);

export default manager;
