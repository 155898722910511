import { isSuccessAction } from '@/Common/ReducerHelpers';
import { actions } from './ClientActions';

const initState = {
  selected: {},
  list: [],
};

export default function user(state = initState, action) {
  if (isSuccessAction(action) && action.type === actions.actionNames.load) {
    const client = action.payload.length > 0 ? action.payload[0] : null;
    return {
      list: action.payload,
      selected: client,
    };
  }

  if (action.type === actions.actionNames.select) {
    return { ...state, selected: action.client };
  }

  return state;
}
