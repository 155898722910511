import { ApiActions, apiAction } from '@/Common/ActionHelpers';
import { convertParamsToQueryString } from '@/api';
import { fileOpen } from '@/Common/FileUtils';
import moment from 'moment';

const bulidSearchParams = (state) => {
  const claimType = state.claimSearch.filter.claimType;

  return {
    ...state.claimSearch.filter,
    client: state.clients.selected.shortName,
    claimType: claimType ? claimType.value : null,
  };
};

class ClaimSearchActions extends ApiActions {
  filterChange = (newFilter) => ({
    type: this.actionNames.filterChange,
    newFilter,
  });
  pageChange = (newIndex) => ({
    type: this.actionNames.pageChange,
    newIndex,
  });
  loadIfNeeded = () => async (dispatch, getState) => {
    if (getState().claimSearch.data.length === 0) dispatch(this.load());
  };
  download = () => async (dispatch, getState) => {
    const state = getState();

    const searchParams = bulidSearchParams(state);
    const fileName = `${
      state.clients.selected.shortName
    }_Export_${moment().unix()}.xlsx`;

    await apiAction(this.actionNames.download, () =>
      fileOpen(
        `/Claims/Download?${convertParamsToQueryString(searchParams)}`,
        fileName,
      ),
    );
  };
  sortChange = (field, direction) => ({
    type: this.actionNames.sortChange,
    field,
    direction,
  });
  load = () => async (dispatch, getState) => {
    const searchParams = bulidSearchParams(getState());

    await apiAction(this.actionNames.load, () =>
      this.api.get(null, searchParams),
    );
  };
}

export const actions = new ClaimSearchActions('claimSearch', 'Claims', [
  'filterChange',
  'pageChange',
  'download',
  'sortChange',
]);
