import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Row, Col } from 'reactstrap';

export class Pager extends React.Component {
  render() {
    const { pageCount, pageIndex } = this.props.pageData;

    if (pageCount < 2) return null;

    const pageList = [];
    const pageLinkCount = 7;

    let minPage = 1;
    let maxPage = pageCount;
    const halfCount = pageLinkCount / 2;

    if (pageCount > pageLinkCount + 2) {
      if (pageIndex > halfCount) {
        minPage = pageIndex - halfCount;

        if (pageIndex < pageCount - halfCount) {
          maxPage = pageIndex + halfCount;
        } else {
          minPage = pageCount - pageLinkCount + 1;
        }
      } else {
        maxPage = pageLinkCount + 1;
      }
    }

    for (let i = 1; i <= pageCount; i++) {
      if (i > 1 && i < pageCount && (i > maxPage + 1 || i < minPage - 1))
        continue;

      if ((i > maxPage && i !== pageCount) || (i < minPage && i > 1)) {
        pageList.push(
          <li key={i} className='text-muted list-inline-item'>
            ...
          </li>,
        );
        continue;
      }

      pageList.push(
        <li key={i} className='list-inline-item'>
          {pageIndex === i - 1 ? (
            <strong>{i}</strong>
          ) : (
            <Button color='link' onClick={() => this.props.onPageChange(i - 1)}>
              {i}
            </Button>
          )}
        </li>,
      );
    }

    var backDisabled = this.props.pageData.pageIndex === 0;
    var forwardDisabled =
      this.props.pageData.pageIndex === this.props.pageData.pageCount;

    return (
      <Row>
        <Col sm={2}>
          <ul className='list-inline'>
            <li className='list-inline-item'>
              <Button
                color='link'
                onClick={() => this.props.onPageChange(0)}
                disabled={backDisabled}
              >
                <FontAwesomeIcon icon={['fas', 'step-backward']} />
              </Button>
            </li>
            <li className='list-inline-item'>
              <Button
                color='link'
                onClick={() =>
                  this.props.onPageChange(this.props.pageData.pageIndex - 1)
                }
                disabled={backDisabled}
              >
                <FontAwesomeIcon icon={['fas', 'backward']} />
              </Button>
            </li>
          </ul>
        </Col>
        <Col className='text-center'>
          <ul className='list-inline'>{pageList}</ul>
        </Col>
        <Col sm={2} className='text-right'>
          <ul className='list-inline'>
            <li className='list-inline-item'>
              <Button
                color='link'
                onClick={() =>
                  this.props.onPageChange(this.props.pageData.pageIndex + 1)
                }
                disabled={forwardDisabled}
              >
                <FontAwesomeIcon icon={['fas', 'forward']} />
              </Button>
            </li>
            <li className='list-inline-item'>
              <Button
                color='link'
                onClick={() =>
                  this.props.onPageChange(this.props.pageData.pageCount - 1)
                }
                disabled={forwardDisabled}
              >
                <FontAwesomeIcon icon={['fas', 'step-forward']} />
              </Button>
            </li>
          </ul>
        </Col>
      </Row>
    );
  }
}
