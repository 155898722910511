import React from 'react';
import { withFormsy } from 'formsy-react';
import NumberFormat from 'react-number-format';
import { NumericInput } from './Numeric';

export const CurrencyDisplay = (props) => (
  <NumberFormat
    fixedDecimalScale
    decimalScale={2}
    thousandSeparator
    prefix={'$'}
    displayType='text'
    {...props}
    value={props.children}
  />
);

export const CurrencyInput = (props) => {
  const className = !this.props.leftAlign ? 'text-right' : '';

  return (
    <NumericInput
      fixedDecimalScale
      decimalScale={2}
      thousandSeparator
      prefix={'$'}
      displayType='text'
      className={className}
      {...this.props}
    />
  );
};

export const FormsyCurrencyInput = withFormsy(CurrencyInput);
