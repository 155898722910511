import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Loading = (props) => {
  const textColor = props.light ? 'text-light' : 'text-muted';
  const spinnerColor = props.light ? 'text-light' : 'text-brand';

  const Display = () => (
    <span className={textColor}>
      {!props.noSpin && (
        <FontAwesomeIcon
          className={`${spinnerColor} mr-2`}
          icon={['fal', 'spinner-third']}
          spin
        />
      )}

      {props.text !== null && props.text !== undefined
        ? props.text
        : 'Loading...'}
    </span>
  );

  const classes = [props.className];

  if (props.center) classes.push('text-center');

  switch (props.size) {
    case 'large':
      return (
        <h1 className={classes.join(' ')}>
          <Display />
        </h1>
      );
    default:
      return (
        <span className={classes.join(' ')}>
          <Display />
        </span>
      );
  }
};

export class StatusDisplay extends React.Component {
  render() {
    const status = this.props.status;

    if (!status) return this.props.children;

    if (status.error) {
      const errorClassName = this.props.errorClassName
        ? `${this.props.errorClassName} text-danger`
        : 'text-danger';

      return (
        <span className={errorClassName}>
          <h4>
            <FontAwesomeIcon
              className='text-danger'
              icon={['fal', 'exclamation-circle']}
            />{' '}
            {this.props.errorTitle || `Error ${this.props.loadingText}`}
          </h4>
          <p>{this.props.status.error}</p>
        </span>
      );
    }

    if (
      status.loading &&
      (this.props.showLoading === undefined || this.props.showLoading)
    )
      return (
        <Loading
          className={this.props.loadingClassName}
          text={this.props.loadingText}
          {...this.props}
        />
      );

    return this.props.children;
  }
}
