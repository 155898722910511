import { isSuccessAction } from '@/Common/ReducerHelpers';
import { actions } from './FilesActions';
import { actions as clientActions } from '../Clients/ClientActions';
import { getLocation } from '@/Common/FileUtils';
import { uniq, startsWith } from 'lodash-es';

const initState = {
  rootFolders: [],
  location: '',
  subFolders: [],
  files: {
    pageIndex: 0,
    pageSize: 25,
    pageCount: 1,
    filter: '',
    page: [],
    all: [],
  },
};

const pagedList = (list, pageSize, pageIndex, filter, location) => {
  var results =
    location && location.trim().length > 0
      ? list.filter((f) => getLocation(f.name) === location)
      : list;

  if (filter && filter.trim().length > 0)
    results = results.filter(
      (f) => f.name.toLowerCase().indexOf(filter.toLowerCase()) > -1,
    );

  const pageCount = Math.floor(results.length / pageSize);
  const pageStart = pageIndex * pageSize;
  const pageEnd = pageStart + pageSize;

  if (results.length > pageSize) results = results.slice(pageStart, pageEnd);

  return {
    pageIndex,
    pageSize,
    pageCount,
    page: results,
    filter,
    all: list,
  };
};

const getSubFolders = (location, files) =>
  location && files
    ? uniq(
        files
          .filter((f) => startsWith(f.name, location))
          .map((f) => f.name.substring(location.length + 1).split('/')[0]),
      ).filter((folder) => folder && folder.indexOf('.') < 0)
    : [];

export default function(state = initState, action) {
  if (action.type === clientActions.actionNames.select) {
    return { ...initState };
  }

  if (isSuccessAction(action) && action.type === actions.actionNames.load) {
    action.payload.forEach(
      (file) => (file.name = file.name.replace(/\\/gi, '/')),
    );
    const rootFolders = uniq(
      action.payload.map((f) => f.name.split('/')[0]),
    ).filter((folder) => folder && folder.indexOf('.') < 0);

    const subFolders = getSubFolders(state.location, action.payload);

    return {
      ...state,
      rootFolders,
      subFolders,
      files: pagedList(
        action.payload,
        state.files.pageSize,
        initState.files.pageIndex,
        '',
        state.location,
      ),
    };
  }

  switch (action.type) {
    case actions.actionNames.filesLocationChange:
      const subFolders = getSubFolders(action.location, state.files.all);

      return {
        ...state,
        location: action.location,
        subFolders,
        files: pagedList(
          state.files.all,
          state.files.pageSize,
          initState.files.pageIndex,
          '',
          action.location,
        ),
      };
    case actions.actionNames.filesPageChange:
      return {
        ...state,
        files: pagedList(
          state.files.all,
          state.files.pageSize,
          action.newIndex,
          state.files.filter,
          state.location,
        ),
      };
    case actions.actionNames.filesFilterChange:
      return {
        ...state,
        files: pagedList(
          state.files.all,
          state.files.pageSize,
          initState.files.pageIndex,
          action.filter,
          state.location,
        ),
      };
    default:
  }

  return state;
}
