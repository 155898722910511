import moment from 'moment';

export const DateDisplay = (props) => {
  if (!props.children) return null;

  let format = 'MM/DD/YYYY';

  if (props.showTime) format = format + ' hh:mm a';

  return (props.utcToLocal
    ? moment.utc(props.children).local()
    : moment(props.children)
  ).format(format);
};
