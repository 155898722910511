import 'react-app-polyfill/ie9';
import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from './App/configureStore';
import { AppContainer } from 'react-hot-loader';
import App from './App/App';
import userManager from './App/Auth/userManager';
import registerServiceWorker from './App/registerServiceWorker';
import initIconLibrary from './IconLibrary';
import { OidcProvider } from 'redux-oidc';
import 'react-dates/initialize';

initIconLibrary();

const rootElement = document.getElementById('root');
const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </OidcProvider>
      </Provider>
    </AppContainer>,
    rootElement,
  );
};

render();

registerServiceWorker();
