import React from 'react';

export const Address = (props) => {
  const address = props.value;
  return (
    <address>
      {address.address1}
      {address.address2}
      {(address.address1 || address.address2) && <br />}
      {address.city}
      {address.city && address.state && <span>,&nbsp;</span>}
      {address.state && address.state}
      {address.state && address.zip && <span>&nbsp;&nbsp;</span>}
      {address.zip}
    </address>
  );
};
