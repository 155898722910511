import { capitalize, trim, snakeCase, get } from 'lodash-es';

export const humanize = (str) =>
  capitalize(trim(snakeCase(str).replace(/_/g, ' ')));

export const sort = (array, key, order = 'asc') =>
  array.sort((a, b) => {
    const valA = get(a, key, null);
    const valB = get(b, key, null);

    if (!valA || !valB) return 0;

    const varA = typeof valA === 'string' ? valA.toUpperCase() : valA;
    const varB = typeof valB === 'string' ? valB.toUpperCase() : valB;

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    return order === 'desc' ? comparison * -1 : comparison;
  });
