import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClientSelector from '@/Clients/ClientSelector';
import userManager from '../Auth/userManager';
import './UserDisplay.scss';

class Header extends React.Component {
  logout = () => {
    userManager.signoutRedirect();
  };
  render() {
    const { user } = this.props;

    if (!user) return null;

    return (
      <div className='d-flex'>
        <FontAwesomeIcon
          icon={['fal', 'user-circle']}
          size='3x'
          className='mr-3'
        />
        <div className='user-info'>
          <strong className='text-brand mr-2'>
            {this.props.user.profile.name}
          </strong>
          <small>
            <Button
              color='link'
              className='text-muted p-0'
              onClick={this.logout}
            >
              <small>Logout</small>
            </Button>
          </small>
          <ClientSelector />
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  user: state.oidc.user,
}))(Header);
