import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from '@/rootReducer';
import { loadUser } from 'redux-oidc';
import userManager from './Auth/userManager';
import { createBrowserHistory } from 'history';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from '@/rootEpic';

export const history = createBrowserHistory();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware();

const initialState = {};

export const store = createStore(
  rootReducer(history),
  initialState,
  composeEnhancer(
    applyMiddleware(routerMiddleware(history), epicMiddleware, thunk),
  ),
);

epicMiddleware.run(rootEpic);

loadUser(store, userManager);
