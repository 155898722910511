import React from 'react';
import { connect } from 'react-redux';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { StatusDisplay } from '@/Components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapDispatchToPropsFactory } from '@/Common/ActionHelpers';
import { actions } from './ClientActions';

class Header extends React.Component {
  componentDidMount() {
    if (this.props.user) {
      this.props.actions.load();
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.user &&
      (!prevProps.user ||
        prevProps.user.profile.sid !== this.props.user.profile.sid)
    ) {
      this.props.actions.load();
    }
  }
  render() {
    const { clients } = this.props;

    return (
      <div>
        <StatusDisplay status={this.props.status}>
          {clients.list.length === 1 && (
            <div className='mt-1'>{this.props.clients.selected.name}</div>
          )}
          {clients.list.length > 1 && (
            <UncontrolledDropdown size='sm' className='client-select'>
              <DropdownToggle className='pb-0 client-select-toggle'>
                <div className='client-select-label'>
                  {this.props.clients.selected &&
                    this.props.clients.selected.name}
                </div>
                <FontAwesomeIcon
                  icon={['fal', 'chevron-down']}
                  className='float-right'
                />
              </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: (data) => ({
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: 'auto',
                        maxHeight: 400,
                      },
                    }),
                  },
                }}
              >
                {clients.list.map((org) => (
                  <DropdownItem
                    key={org.id}
                    active={org.id === this.props.clients.selected.id}
                    onClick={() => this.props.actions.select(org)}
                  >
                    {org.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </StatusDisplay>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    clients: state.clients,
    user: state.oidc.user,
    status: state.status.clients.load,
  }),
  mapDispatchToPropsFactory(actions),
)(Header);
