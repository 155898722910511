import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from './Layout/Layout';
import Routes from './Routes';
import Auth from './Auth/Auth';
import { hot } from 'react-hot-loader/root';

class App extends Component {
  render() {
    if (!this.props.user) return <Auth />;

    return (
      <Layout>
        <Routes />
      </Layout>
    );
  }
}

const hotApp = process.env.NODE_ENV === 'development' ? hot(App) : App;

export default connect((state) => ({
  location: state.router.location.pathname,
  user: state.oidc.user,
}))(hotApp);
