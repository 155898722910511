import React from 'react';
import { withFormsy } from 'formsy-react';
import { Input, Label, FormFeedback, FormGroup, Col } from 'reactstrap';
import NumberFormat from 'react-number-format';

export class NumericInput extends React.Component {
  changeValue = (values) => {
    const value = values.value !== '' ? parseFloat(values.value) : 0;

    if (this.props.setValue) {
      this.props.setValue(value);
    }

    if (this.props.onChange) {
      this.props.onChange(this.props.name, value);
    }
  };
  render() {
    const classes = ['form-group'];

    const isValid =
      this.props.isValid &&
      (!this.props.isFormSubmitted() || this.props.isValid());
    const errorMessage =
      this.props.getErrorMessage && this.props.getErrorMessage();

    if (!isValid) classes.push('has-error');

    if (this.props.className) classes.push(this.props.className);

    const input = (
      <NumberFormat
        fixedDecimalScale={this.props.fixedDecimalScale}
        decimalScale={this.props.decimalScale}
        thousandSeparator={this.props.thousandSeparator}
        mask={this.props.mask}
        prefix={this.props.prefix}
        format={this.props.format}
        displayType='input'
        customInput={Input}
        bsSize={this.props.bsSize}
        className={this.props.className}
        onValueChange={this.changeValue}
        value={this.props.getValue ? this.props.getValue() : this.props.value}
        valid={isValid}
        disabled={this.props.disabled}
        name={this.props.name}
        placeholder={this.props.placeholder}
      />
    );

    if (this.props.noFormGroup) return input;

    if (this.props.labelWidth) {
      return (
        <FormGroup className={classes.join(' ')} row>
          {this.props.label && (
            <Label sm={this.props.labelWidth} for={this.props.id}>
              {this.props.label}
            </Label>
          )}
          <Col sm={12 - this.props.labelWidth}>
            {input}
            {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
          </Col>
        </FormGroup>
      );
    }

    return (
      <div className={classes.join(' ')}>
        {this.props.label && (
          <Label for={this.props.id}>{this.props.label}</Label>
        )}
        {input}
        <FormFeedback>{errorMessage}</FormFeedback>
      </div>
    );
  }
}

export const FormsyNumericInput = withFormsy(NumericInput);
